<template>
  <section class="section-casino">
    <div class="section-casino__top">
      <BaseImg
        src="/assets/images/components/mobile/pages/home/section-casino/visual-section-casino.webp"
        class="visual-casino"
        alt="visual-casino"
      />
      <div class="content">
        <div class="content__title">Sảnh Live Casino</div>
        <p class="content__sub-title">Chơi mạnh thưởng lớn</p>
      </div>
    </div>
    <div class="section-casino__list">
      <nuxt-link
        v-for="(game, index) of (providerCasino as any[]).filter((game) => game.alias !== 'all')"
        :key="index"
        :to="`${PAGE.CASINO}/${game.alias}`"
        class="item"
      >
        <BaseImg :src="game?.icon" class="icon-casino" alt="icon-casino" />
        <span class="item__title">{{ game?.display_name }}</span>
      </nuxt-link>
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGameStore } from '~/store/game'
import { PAGE } from '~/constants/router'
import { useCasino } from '~/composables/casino/useCasino'

const { $pinia } = useNuxtApp()
const storeGame = useGameStore($pinia)
const { providerCasino } = storeToRefs(storeGame)

const { fetchGameCasino } = useCasino()

onMounted(() => {
  if (!providerCasino.value.length) {
    fetchGameCasino()
  }
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-casino/index.scss"></style>
