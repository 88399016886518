<template>
  <component :is="component" />
</template>

<script setup lang="ts">
import useBase from '~/composables/useBase'
import Desktop from '~/components/desktop/pages/home/index.vue'
import Mobile from '~/components/mobile/pages/home/index.vue'

useBase()

const { $device } = useNuxtApp()
const component = $device.isMobileOrTablet ? Mobile : Desktop
</script>
