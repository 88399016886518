<template>
  <section class="jackpot-game">
    <div class="jackpot-game__title">Tổng hũ sắp nổ</div>
    <div class="jackpot-game__amount">
      <AnimateNumber :number="sumJackpot['jackpotNohu']" :show-coin="false" />
      VND
    </div>
    <nuxt-link to="/game/no-hu" class="jackpot-game__see-all">Xem tất cả</nuxt-link>
    <div class="jackpot-game__list-game">
      <client-only>
        <Swiper ref="mySwiper" v-bind="swiperOption" class="swiper-jackpot">
          <SwiperSlide v-for="(game, index) in listGameJackpotHome" :key="index" class="jackpot-game__list--item">
            <CommonGamesItemGame :item="game" class-name="game-jackpot" />
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper'
import { useAppStore } from '@/store/app'
import AnimateNumber from '~/components/common/animate-number.vue'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { useJackpot } from '~/composables/game/useJackpot'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const { fetchListGameJackpot, listGameJackpotHome } = useGame()
const { fetchSumJackpot, fetchJackpot } = useJackpot()

const swiperOption = reactive({
  slidesPerView: 3.9,
  slidesPerGroup: 1,
  observer: true,
  loop: true,
  // autoplay: {
  //   delay: 3000,
  //   disableOnInteraction: false
  // },
  centeredSlides: false,
  modules: [Autoplay, Navigation]
})

useAsyncData(async () => {
  await fetchListGameJackpot()
  await fetchSumJackpot()
  await fetchJackpot()
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-jackpot/index.scss"></style>
