<template>
  <div class="home">
    <div class="home-top">
      <SectionBanner />
    </div>
    <div class="home__content">
      <SectionGameCenter />
      <SectionCasino />
      <SectionJackpot />
      <SectionRecommened />
      <Introduce />
      <Contact />
      <SeoContent />
      <SeoImage />
    </div>
  </div>
</template>
<script setup lang="ts">
import SectionCasino from '~/components/mobile/pages/home/section-casino/index.vue'
import SectionJackpot from '~/components/mobile/pages/home/section-jackpot/index.vue'
const SectionBanner = defineAsyncComponent(() => import('~/components/mobile/pages/home/banner/index.vue'))
const SectionGameCenter = defineAsyncComponent(() => import('@/components/mobile/pages/home/game-center/index.vue'))
const SectionRecommened = defineAsyncComponent(
  () => import('@/components/mobile/pages/home/section-recommend/index.vue')
)
const Introduce = defineAsyncComponent(() => import('@/components/mobile/pages/home/section-introduce/index.vue'))
const Contact = defineAsyncComponent(() => import('@/components/mobile/pages/home/section-contact/index.vue'))
const SeoContent = defineAsyncComponent(() => import('@/components/common/seo-content.vue'))
const SeoImage = defineAsyncComponent(() => import('@/components/common/branch-info.vue'))
</script>
<style scoped lang="scss" src="~/assets/scss/components/mobile/pages/home/index.scss"></style>
