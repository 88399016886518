<template>
  <div class="home">
    <LazyHydrationWrapper when-visible>
      <div class="home-top">
        <BannerSection />
      </div>
    </LazyHydrationWrapper>
    <div class="home__content">
      <SectionGameCenter />
      <SectionCasino />
      <SectionJackpot />
      <SectionRecommentGame is-auto-play />
      <SectionNews />
      <ContentSeo />
    </div>
  </div>
</template>
<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
const BannerSection = defineAsyncComponent(() => import('@/components/desktop/pages/home/hero-banner/index.vue'))
const SectionJackpot = defineAsyncComponent(() => import('@/components/desktop/pages/home/section-jackpot/index.vue'))
const SectionCasino = defineAsyncComponent(() => import('@/components/desktop/pages/home/section-casino/index.vue'))
const SectionGameCenter = defineAsyncComponent(() => import('@/components/desktop/pages/home/game-center/index.vue'))
const SectionRecommentGame = defineAsyncComponent(() => import('@/components/common/recommended-game.vue'))
const SectionNews = defineAsyncComponent(() => import('@/components/desktop/pages/home/news/index.vue'))
const ContentSeo = defineAsyncComponent(() => import('@/components/common/seo-content.vue'))
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/index.scss"></style>
